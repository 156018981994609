import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import { uniqueId } from "lodash";

import Loading from "../components/_Loading/Loading";

export { default as Root } from "../components/_Loading/Root";

const useLoading = () => {
    const loading = useCallback((id) => {
        if (!id) {
            const id = uniqueId("loading_");
            const node = document.createElement("div");
            node.setAttribute("class", "loading-root-container");
            node.setAttribute("data-id", id);
            document.querySelector("#loading-root").appendChild(node);

            ReactDOM.render(<Loading />, node);
            return id;
        }

        const loadingContainer = document.querySelector(
            `.loading-root-container[data-id=${id}]`
        );

        if (loadingContainer !== null) {
            document
                .querySelector("#loading-root")
                .removeChild(loadingContainer);
        }
    }, []);

    return { loading };
};

export default useLoading;
