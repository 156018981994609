import React from "react";
import Container from "../../components/Container";
import CategoryCatalog from "../../components/CategoryCatalog";
import AplicationCatalog from "../../components/AplicationCatalog";

const ProductsAndMarket = () => {
    return (
        <Container>
            <div className="page-disabled">
                <h1>
                    ESTÁ SEÇÃO ESTÁ EM MANUTENÇÃO <br /> EM BREVE, NOVOS
                    LANÇAMENTOS
                </h1>

                <p>Para qualquer informação, favor utilizar o e-mail</p>
                <a
                    href="mailto:faleconosco@noordhen.com.br"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    faleconosco@noordhen.com.br
                </a>

                <span>Obrigado</span>
            </div>
            <h1>Produtos e Mercados</h1>
            <hr />
            <CategoryCatalog />
            <AplicationCatalog />
        </Container>
    );
};

export default ProductsAndMarket;
